import { useEffect, useMemo, useState } from 'react';

import { useGetAssistantsQuery } from '@/shared/generated/graphql';

export type AssistantType = {
  id: number;
  value: string;
};

export const useAssistants = () => {
  const [list, setList] = useState<AssistantType[]>([]);
  const { data } = useGetAssistantsQuery();

  useEffect(() => {
    if (data?.getAssistants) {
      setList(
        data.getAssistants.map((assistant) => ({
          id: assistant.id,
          value: assistant.userName || '',
        })),
      );
    }
  }, [data]);

  return useMemo(() => ({ list }), [list]);
};
