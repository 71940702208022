import { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetServerSideProps } from 'next';
import Image from 'next/image';
import Link from 'next/link';
// import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import logo from '@public/assets/logo.svg';
import { Button, Typography } from 'antd';
import * as Comlink from 'comlink';
import { Container, Row } from 'src/shared/components/layout';
import styled from 'styled-components';

import { useAuth } from '@/_pages/auth/hooks/useAuth';
import { DebugTools } from '@/_pages/layouts/DebugTools';
import MainPageForExportedMode from '@/_pages/MainPageForExportedMode';
import { ContainerBG } from '@/shared/components';
import { COLORS } from '@/shared/constants/colors';
import { isExported } from '@/shared/constants/constants';
// import { useIsPwa } from '@/services/hooks/use-is-pwa';
import { useRegistrationStore } from '@/shared/store/registration.store';
import { Title } from '@/shared/uikit/Title';
import { sleep } from '@/shared/utils/misc';

import appInfo from '../../app-info.json';
import { IWorker } from '../../worker';
// import { DebugCrypto } from './debug/crypto';

export default isExported ? MainPageForExportedMode : IndexPage;

type IndexPageProps = {
  beneficiaryJWT: string | undefined;
  delegationRequestId: string | undefined;
};

function IndexPage({
  beneficiaryJWT,
  delegationRequestId,
}: IndexPageProps): ReactNode {
  const { setBeneficiaryJWT } = useRegistrationStore();

  useAuth();

  useEffect(() => {
    setBeneficiaryJWT(beneficiaryJWT);
  }, []);

  useEffect(() => {
    (async () => {
      await sleep(1000);
      const worker = new Worker(
        new URL('../../worker/index.ts', import.meta.url),
      );
      const obj = Comlink.wrap<IWorker>(worker);
      await obj.echo('Hello world from main thread!');
    })();
  }, []);

  return (
    <RowStyled justifyContent={'center'}>
      <Container maxWidth={390} width={'100%'} paddingY={10}>
        <Row direction="column" alignCenter height="100%">
          <Container
            paddingX={30}
            width="100%"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Image src={logo} alt="logo" width={150} height={96} />
          </Container>

          <Container marginTop={32}>
            <ContainerBG>
              {delegationRequestId ? (
                <LoginAssistant delegationRequestId={delegationRequestId} />
              ) : (
                <LoginDefault beneficiaryJWT={beneficiaryJWT} />
              )}
            </ContainerBG>
          </Container>
          <Container marginTop={24} width="100%" textCenter>
            <Typography.Text style={{ opacity: 0.1 }}>
              v {appInfo.version}
            </Typography.Text>
          </Container>
        </Row>
      </Container>
      <DebugTools />
    </RowStyled>
  );
}

const LoginDefault: FC<{ beneficiaryJWT?: string }> = ({ beneficiaryJWT }) => {
  const { t } = useTranslation('welcome', { keyPrefix: 'welcome' });

  return (
    <Container
      paddingX={32}
      paddingY={40}
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Title level={2}>{t('welcome')}</Title>

      <Container marginTop={4}>
        <Typography.Title level={5}>{t('title')}</Typography.Title>
      </Container>

      <Container marginTop={24} style={{ width: '100%' }}>
        {beneficiaryJWT ? (
          <Link href={'/auth/register'} style={{ width: '100%' }}>
            <Button block size="large">
              {t('signup_button')}
            </Button>
          </Link>
        ) : (
          <>
            <Link href={'/auth/login/personal-account'}>
              <Button size="large" type="primary" style={{ width: '100%' }}>
                {t('log_in_button')}
              </Button>
            </Link>
            <br />
            <br />
            <Link href={'/auth/login'}>
              <Button size="large" style={{ width: '100%' }}>
                {t('log_in_next_owner_button')}
              </Button>
            </Link>
          </>
        )}
      </Container>
    </Container>
  );
};

const LoginAssistant: FC<{ delegationRequestId: string }> = ({
  delegationRequestId,
}) => {
  const { t } = useTranslation('welcome', { keyPrefix: 'assistant' });
  const linkHref = {
    pathname: '/auth/login/assistant',
    query: {
      delegationRequestId: delegationRequestId,
    },
  };

  return (
    <Container paddingX={40} paddingY={40}>
      <Title level={2}>{t('welcome')}</Title>

      <Container marginTop={4}>
        <Typography.Title level={5}>{t('title')}</Typography.Title>
      </Container>

      <Container marginTop={24}>
        <Typography.Text>{t('message')}</Typography.Text>
      </Container>

      <Container marginTop={76}>
        <Link href={linkHref} data-testid={'loginBtn'}>
          <Button block size="large">
            {t('log_in_button')}
          </Button>
        </Link>
      </Container>
    </Container>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  query,
  locale,
}) => {
  const _delegationRequestId = query.delegationRequestId;
  const delegationRequestId = Array.isArray(_delegationRequestId)
    ? _delegationRequestId[0]
    : _delegationRequestId;

  if (!delegationRequestId) {
    return {
      props: {
        beneficiaryJWT: (query?.beneficiarydata as string) || null,
        ...(await serverSideTranslations(locale ?? 'en', [
          'welcome',
          'signup',
          'auth',
        ])),
      },
    };
  }
  return {
    props: {
      delegationRequestId: delegationRequestId,
      beneficiaryJWT: (query?.beneficiarydata as string) || null,
      ...(await serverSideTranslations(locale ?? 'en', ['welcome', 'signup'])),
    },
  };
};

const RowStyled = styled(Row)`
  height: 100dvh;
  background-color: ${COLORS.colorBgLayout};
`;
