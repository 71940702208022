import type { ChangeEvent, ClipboardEvent, FC, PropsWithChildren } from 'react';
import { useTranslation } from 'next-i18next';
import { App, Input as AntdInput, Typography } from 'antd';
import styled from 'styled-components';

import { useMnemonicPhraseStore } from '@/entities/mnemonic/store/mnemonic-phrase.store';
import { Row } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';
import { isExported } from '@/shared/constants/constants';

type EnterMnemonicPhraseProps = {
  hideControls?: boolean;
};

export const EnterMnemonicPhrase: FC<EnterMnemonicPhraseProps> = ({
  hideControls,
}) => {
  const { t } = useTranslation('auth', { keyPrefix: 'login' });
  const app = App.useApp();
  const { segments, setSegments, deleteSegments } = useMnemonicPhraseStore();

  function onPaste(event: ClipboardEvent<HTMLInputElement>): void {
    event.preventDefault();
    const pasted = event.clipboardData.getData('text/plain');

    const wordsArray = pasted.trim().split(/\s+/).slice(0, segments.length);
    // Paste one word
    if (wordsArray.length === 1) {
      const wordsIdx = (event.target as HTMLInputElement).dataset.testid?.split(
        '-',
      )[1];
      if (wordsIdx) {
        const arr = [...segments];
        arr[+wordsIdx - 1] = wordsArray[0];
        setSegments(arr);
      }
      // Paster N words
    } else if (wordsArray.length < 12) {
      setSegments([
        ...wordsArray,
        ...(Array.from({ length: 12 - wordsArray.length }).fill(
          '',
        ) as Array<string>),
      ]);
    } else {
      setSegments(wordsArray);
    }
  }

  const onPasteClick = async () => {
    const pasted = await navigator.clipboard.readText();
    const wordsArray = pasted.trim().split(' ').slice(0, segments.length);
    if (wordsArray.length < 12) {
      setSegments([
        ...wordsArray,
        ...Array.from<string>({ length: 12 - wordsArray.length }).fill(''),
      ]);
    } else {
      setSegments(wordsArray);
    }
  };

  const clearSeed = () => {
    deleteSegments();
    app.message.success(t('seedCleared'));
  };

  function update(index: number) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const enteredValue = event.target.value.trim().toLowerCase();
      const wordsArray = enteredValue.split(/\s+/);

      if (wordsArray.length === 12) {
        setSegments(wordsArray.slice(0, segments.length));
      } else {
        const word = enteredValue.replace(/[^A-Za-z]/gi, '');
        setSegments([
          ...segments.slice(0, index),
          word,
          ...segments.slice(index + 1),
        ]);
      }
    };
  }

  function getEmptyInput(target: HTMLInputElement, index: number) {
    if (target.value) {
      return null;
    } else {
      const firstEmptyInput = document.querySelector(
        `#inputMnemonic${index}`,
      ) as HTMLInputElement;
      if (firstEmptyInput) {
        if (firstEmptyInput.value) {
          return getEmptyInput(target, index + 1);
        } else {
          return firstEmptyInput.focus();
        }
      } else {
        return null;
      }
    }
  }

  return (
    <>
      <InputsGrid>
        {segments.map((item, index: number) => (
          <Row
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            key={index}
          >
            <Number>{index + 1}</Number>
            <Input
              id={`inputMnemonic${index + 1}`}
              data-testid={`inputMnemonic-${index + 1}`}
              size="small"
              value={item as string}
              onPaste={onPaste}
              onFocus={(e) => {
                getEmptyInput(e.target, 1);
              }}
              onInput={update(index)}
              autoComplete="off"
            />
          </Row>
        ))}
      </InputsGrid>
      <Row width={'100%'} alignCenter justifyContent={'center'}>
        {!isExported && !hideControls ? (
          <Links>
            <Link onClick={onPasteClick}>{t('pasteFromClipboard')}</Link>
            <Link onClick={clearSeed}>{t('clearMnemonic')}</Link>
          </Links>
        ) : null}
      </Row>
    </>
  );
};

type LinkProps = PropsWithChildren & {
  onClick: () => void;
};

const Link: FC<LinkProps> = ({ children, onClick }) => (
  <Typography.Link onClick={onClick} underline>
    {children}
  </Typography.Link>
);

const Links = styled.div`
  margin: 25px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const InputsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  column-gap: 28px;
  row-gap: 12px;
  grid-template-columns: repeat(2, 2fr);
  grid-template-rows: repeat(6, 40px);
`;

const Number = styled.span`
  width: 28px;
  font-size: 16px;
`;

const Input = styled(AntdInput)`
  &&& {
    height: 40px;
    padding: 8px 12px;
    flex: 1;
    border: 1px solid ${COLORS.colorBorder};
    box-sizing: border-box;
    outline: 0;

    &:focus {
      border: 1px solid ${COLORS.colorPrimaryBorderFocus};
    }

    &:hover {
      border: 1px solid ${COLORS.colorPrimaryBorderHover};
    }
  }
`;
