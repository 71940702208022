import { create } from 'zustand';

type MyHubProfileStoreProps = {
  firstName: string;
  lastName: string;
  setData: (data: { firstName: string; lastName: string }) => void;
};

export const useMyHubProfile = create<MyHubProfileStoreProps>((set) => ({
  firstName: '',
  lastName: '',
  setData: ({ firstName, lastName }) => set({ firstName, lastName }),
}));
