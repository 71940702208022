import { useMemo } from 'react';

import { ProfileType, useWhoamiLazyQuery } from '@/shared/generated/graphql';
import { EncryptionType } from '@/shared/lib/secure-json/core/crypto-core/types';
import { useAuthStore } from '@/shared/store/auth.store';
import { useWhoamiStore } from '@/shared/store/whoami.store';

export const useInitWhoAmi = () => {
  const setIsBeneficiary = useAuthStore((s) => s.setIsBeneficiary);
  const [setPublicKey, setEncryptionType] = useWhoamiStore((s) => [
    s.setPublicKey,
    s.setEncryptionType,
  ]);
  const [whoami] = useWhoamiLazyQuery();

  const initWhoAmi = async (token: string) => {
    const resWhoami = await whoami({
      context: {
        headers: {
          authorization: token,
        },
      },
    });

    const profile = resWhoami.data?.whoami.profile;
    setIsBeneficiary(profile?.type === ProfileType.Beneficiary);
    if (profile?.publicKey) {
      setPublicKey(profile?.publicKey);
    }
    const encryptionType = (profile?.encryptionType?.title ??
      'rsa') as EncryptionType; // TODO default encryption
    setEncryptionType(encryptionType);

    return profile;
  };

  return useMemo(
    () => ({
      initWhoAmi,
    }),
    [initWhoAmi],
  );
};

// Auth provider
// const initWhoAmi = async () => {
//   try {
//     const resWhoami = await whoami({
//       context: {
//         headers: {
//           authorization: token,
//         },
//       },
//     });
//     if (!resWhoami.data) {
//       localStorage.clear();
//       await router.push('/');
//       setLoading(false);
//       return;
//     }
//     const profile = resWhoami.data.whoami.profile;
//     if (profile) {
//       const encryptionType = (profile.encryptionType?.title ??
//         'rsa') as EncryptionType;
//       setEncryptionType(encryptionType);
//     }
//   } catch (error) {
//     console.log(error);
//     localStorage.clear();
//     await router.push('/');
//     setLoading(false);
//   }
// };
