import { useMemo, useState } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';

import { useSynchronizerStore } from '@/_app/providers/synchronizer/synchronizer.store';
import { useAssistantStore } from '@/entities/assistant';
import { useMnemonicPhraseStore } from '@/entities/mnemonic';
import { useDashboard } from '@/features/dashboard/hooks/use-dashboard';
import {
  AccountStatus,
  useLoginViaPersonalAccountCodeMutation,
  useLogoutMutation,
  useWhoamiLazyQuery,
} from '@/shared/generated/graphql';
import { useInitWhoAmi } from '@/shared/hooks/useInitWhoAmi';
import { useSecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';
import { useAuthStore } from '@/shared/store/auth.store';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { ERegisrationStep } from '@/shared/store/registration.store';
import { useSettingsStore } from '@/shared/store/settings.store';
import { useWhoamiStore } from '@/shared/store/whoami.store';

// SSO auth legacy
export const useAuth = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();
  const { clear: clearDashboard } = useDashboard();
  const [whoami] = useWhoamiLazyQuery();
  const { initWhoAmi } = useInitWhoAmi();
  const [login] = useLoginViaPersonalAccountCodeMutation();
  const [logout] = useLogoutMutation();
  const { setToken, setAccount } = useAuthStore();
  const { mnemonicPhrase } = useMnemonicPhraseStore();
  const clearAuthStore = useAuthStore((s) => s.clear);
  const clearSettingsStore = useSettingsStore((s) => s.clear);
  const clearKeyPairStore = useKeyPairStore((s) => s.clear);
  const clearSynchronizerStore = useSynchronizerStore((s) => s.clear);
  const clearAssistantStore = useAssistantStore((s) => s.clear);
  const clearWhoamiStore = useWhoamiStore((s) => s.clear);

  const clearSecureJsonCollectionsStore = useSecureJsonCollectionsStore(
    (s) => s.clear,
  );

  const appLogin = async (authGrantToken: string) => {
    const resLogin = await login({
      variables: {
        code: authGrantToken,
      },
    });
    const loginData = resLogin.data?.loginViaPersonalAccountCode;
    setToken(loginData?.token);
    setAccount(loginData?.account);

    if (loginData?.token) {
      const profile = await initWhoAmi(loginData?.token);

      if (profile?.status !== AccountStatus.Active) {
        await router.push(
          `/auth/register?step=${ERegisrationStep.CREATE_SEED}`,
        );
        return;
      }
    }

    const isMnemonic = !mnemonicPhrase || mnemonicPhrase.length === 0;
    if (isMnemonic) {
      if (router.query) {
        await router.push(
          {
            pathname: '/auth/login/mnemonic-phrase',
            query: { reset: true },
          },
          undefined,
        );
        setLoading(false);

        return;
      }
      await router.push('/auth/login/mnemonic-phrase', undefined);
    }
  };

  const appLogout = async (
    redirectURL?: Parameters<NextRouter['push']>['0'],
  ) => {
    setLoading(true);
    const resultWhoami = await whoami();

    if (
      resultWhoami &&
      resultWhoami.data &&
      resultWhoami.data.whoami &&
      resultWhoami?.data.whoami.sessions
    ) {
      try {
        await logout({
          variables: {
            sessionIds: [resultWhoami?.data?.whoami?.sessions[0]?.id],
          },
        });
      } catch (error) {
        console.log('Logout error');
        console.error(error);
      }
    }

    clearKeyPairStore();
    clearDashboard();
    clearAuthStore();
    clearSynchronizerStore();
    clearSettingsStore();
    clearSecureJsonCollectionsStore();
    clearAssistantStore();
    clearWhoamiStore();
    clearSynchronizerStore();
    localStorage.clear();
    sessionStorage.clear();
    await router.push(redirectURL ?? '/');
    await apolloClient.resetStore();
  };

  return useMemo(
    () => ({
      appLogin,
      appLogout,
      loading,
    }),
    [loading, appLogin],
  );
};
